import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { popoverState } from "../../../states";
import { AiOutlineClose } from "react-icons/ai";
import styles from "./Popover.module.css";
import "../../../styles/main.css";

const Popover = () => {
  const [popover, setPopover] = useRecoilState(popoverState);
  // useEffect(() => {
  //   if (popover) {
  //     const timer = setTimeout(() => {
  //       setPopover(null);
  //     }, 10000);
  //     return () => clearTimeout(timer);
  //   }
  // }, [popover, setPopover]);

  if (!popover) return null;

  return (
    <div
      className={`${styles.bounce} popover fade ${
        popover != null ? "show" : ""
      } bs-popover-top`}
      role="tooltip"
      style={{
        position: "absolute",
        inset: "auto auto 100px auto",
        margin: "0px",
        backgroundColor: "#5431BB",
        color: "white",
      }}
      data-popper-placement="top"
    >
      <div
        className="popover-arrow"
        style={{ position: "absolute", left: "50%" }}
      ></div>
      <div className="popover-body d-flex flex-row justify-content-between align-items-start fs-12">
        <div className="text-white">{popover}</div>
        <AiOutlineClose
          className="fs-9 color-white cursor-pointer"
          style={{ margin: "-10px !important" }}
          onClick={() => setPopover(null)}
        />
      </div>
    </div>
  );
};

export default Popover;

import { AxiosResponse } from 'axios';
import { openDB } from 'idb';
import UAParser from 'ua-parser-js';

const dbName = 'my-database';
const storeName = 'userMetrics';

const useDb = () => {
  const dbPromise = openDB(dbName, 2, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(storeName)) {
        db.createObjectStore(storeName, { keyPath: 'uid', autoIncrement: true });
      }
    },
  });

  const addItem = async (value: any) => {
      const db = await dbPromise;
      return await db.add(storeName, value);
  };

  const getItem = async (uid: number) => {
    try {
      const db = await dbPromise;
      return await db.get(storeName, uid);
    } catch (error) {
      console.error('Error getting item from IndexedDB', error);
    }
  };

  const getAllItems = async () => {
    try {
      const db = await dbPromise;
      const response = await db.getAll(storeName);
	  return response;
    } catch (error) {
      console.error('Error getting all items from IndexedDB', error);
    }
  };

  const deleteItem = async (uid: number) => {
    try {
      const db = await dbPromise;
      return await db.delete(storeName, uid);
    } catch (error) {
      console.error('Error deleting item from IndexedDB', error);
    }
  };

  const clearAllItems = async () => {
    try {
      const db = await dbPromise;
      return await db.clear(storeName);
    } catch (error) {
      // console.error('Error clearing items from IndexedDB', error);
    }
  };

  // delete all data from store
  const deleteStore = async () => {
    const db = await dbPromise;
    db.close();

    const newVersion = db.version + 1;
    await openDB(dbName, newVersion, {
      upgrade(db) {
        if (db.objectStoreNames.contains(storeName)) {
          db.deleteObjectStore(storeName);
          console.log(`Object store ${storeName} deleted.`);
        }
      },
    });
  };

  const deleteDatabase = async () => {
    const db = await dbPromise;
    db.close();

    await indexedDB.deleteDatabase(dbName);
    console.log(`Database ${dbName} deleted.`);
  };

  const storeResponseInDB = async (response: AxiosResponse) => {
    const parser = new UAParser();
    const uaResult = parser.getResult();

	const email_id = localStorage.getItem('email_id');
    const user_id = localStorage.getItem('user_id');
    const now = new Date();
    const formattedDateTime = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, "0")}-${String(now.getDate()).padStart(2, "0")} ${String(now.getHours()).padStart(2, "0")}:${String(now.getMinutes()).padStart(2, "0")}:${String(now.getSeconds()).padStart(2, "0")}`;

    const responseObject = {
    //   uid: user_id,
      Email: email_id,
      Timestamp: formattedDateTime,
      url: response.request.responseURL,
      // Navigation: response.config.method,
      Navigation: window.location.pathname + window.location.search,
      Errormsg: response?.data?.detail || response?.statusText || 'OK',
      platform: uaResult.os.name || 'Unknown OS',
      device: uaResult.device.model || uaResult.device.type || 'Unknown Device',
      browser: `${uaResult.browser.name} - ${uaResult.browser.version}`  || 'Unknown Browser'
    };

    addItem(responseObject)
      .then(() => {
        // console.log('Item added successfully',);
      })
      .catch((error) => {
        console.error('Error adding item:', error);
      });
  }

  return {
    addItem,
    getItem,
    getAllItems,
    deleteItem,
    clearAllItems,
    storeResponseInDB,
    deleteStore,
    deleteDatabase
  }
}
export { useDb }

// External libraries
import { RecoilRoot } from "recoil";
import ReactDOM from 'react-dom/client';

// CSS
import './index.css';

// Components
import App from './App';
import reportWebVitals from './reportWebVitals';
import ErrorBoundaryWithNavigate from "./helpers/ErrorBoundary";
import { BrowserRouter } from "react-router-dom";


const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	// <RecoilRoot>
	// 	<ErrorBoundaryWithNavigate>
	//         <App />
	//     </ErrorBoundaryWithNavigate>
	// </RecoilRoot>
	<BrowserRouter>  {/* Ensures that useNavigate can be used */}
		<RecoilRoot>
			<ErrorBoundaryWithNavigate>
				<App />
			</ErrorBoundaryWithNavigate>
		</RecoilRoot>
	</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

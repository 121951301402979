import { useState } from "react";
import { useRecoilState } from "recoil";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import { IoSearchSharp } from "react-icons/io5";

// CSS
import "../../styles/main.css";

// Components
import {
  Button,
  ButtonTheme,
  ButtonSize,
  ButtonVariant,
} from "../ui/button/Button";
import {
  Heading,
  TypographyColor,
  TypographyType,
} from "../ui/typography/Heading";
import Body, { BodyType, BodyColor } from "../ui/typography/Body";
import SearchWithSuggestions from "../ui/search/SearchWithSuggestions";
import { SpinnerLite } from "../ui/spinner/SpinnerLite";
import Modal from "../ui/modal/Modal";
import { spinnerLiteState, mapFeatureState } from "../../states";

// Utilities
import WorkInProgressImage from "../../utils/images/Error State-02.png";
import { RouteConstants } from "../../constants";
import { useMapsService } from "../../services";
import { useMapHelpers } from "../../helpers";

interface ExploreNowProps {
  setRequestDataDrawerOpen: (requestDataDrawerOpen: boolean) => void;
}
const ExploreNow = ({ setRequestDataDrawerOpen }: ExploreNowProps) => {
  const mapsService = useMapsService();
  const navigate = useNavigate();
  const [mapFeatures, setMapFeatures] = useRecoilState(mapFeatureState);
  const [spinnerLite, setSpinnerLite] = useRecoilState(spinnerLiteState);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [results, setResults] = useState<any>(mapFeatures.suggestions);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedValue, setSelectedValue] = useState<string>("");
  const [initialData, setinitialData] = useState<any>([]);
  const [suggestions, setSuggestions] = useState<any>(mapFeatures.suggestions);
  const [hasData, setHasData] = useState(true);
  const [stateAndDistrict, setstateAndDistrict] = useState<any>([]);
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const [searchDisabled, setSearchDisabled] = useState(false);
  const { getErrorMsg } = useMapHelpers();

  const handleInputChange = (value: string) => {
    const lowercasedValue = value.toLowerCase();
    if (value.length > 0) {
      const filteredData = initialData.filter((data: any) =>
        data.geo_value.toLowerCase().includes(lowercasedValue)
      );
      setSuggestions(filteredData);
      setSearchTerm(value);
      if (filteredData.length === 0) {
        setHasData(false);
      } else {
        setHasData(true);
      }
    } else {
      setSearchTerm("");
      setSuggestions(initialData);
      setstateAndDistrict(initialData);
      setHasData(true);
    }
  };


  const handleSelectValue = (value: string) => {
    const lowercasedValue = value.toLowerCase();
    const filteredData = mapFeatures.suggestions?.find(
      (item: any) =>
        item.geo_value?.toLowerCase().includes(lowercasedValue) ||
        item?.children?.find((child: any) =>
          child.geo_value?.toLowerCase().includes(lowercasedValue)
        )
    );
    setSearchTerm("");
    if (filteredData) {
      setHasData(true);
      setResults([filteredData]);
      setSelectedValue(value);
      setSuggestions([]);
      setShowPlaceholder(false)
      setSearchDisabled(true);
    } else {
      setHasData(false);

    }

  };


  const handleCloseSelected = () => {
    setSuggestions(stateAndDistrict);
    setResults(mapFeatures.suggestions);
    setSearchDisabled(false);
    setShowPlaceholder(true);
    setSelectedValue("");
  };

  const handleModalOpen = (flag: boolean) => {
    setShowModal(flag);
    if (flag === true) {
      setSpinnerLite(true);
      mapsService
        .getExploreNow()
        .then((data) => {
          const StateDistrictdata: any[] = [];
          for (let i = 0; i < data.length; i++) {
            StateDistrictdata?.push(data?.[i]);
            StateDistrictdata?.push(...data?.[i]?.children);
          }
          setinitialData(StateDistrictdata);
          setstateAndDistrict(StateDistrictdata);

          setMapFeatures((prevMapFeatures) => ({
            ...prevMapFeatures,
            suggestions: data,
          }));
          setSuggestions(StateDistrictdata);
          setResults(data);
          setSpinnerLite(false);
        })
        .catch((error) => {
          setSpinnerLite(false);
          getErrorMsg(error);
        });
    } else {
      setHasData(true);
      setSelectedValue("");
      setSearchTerm("");
      setSearchDisabled(false);
      setShowPlaceholder(true);
    }
  };

  const handleViewAvailableStates = () => {
    setHasData(true);
    setSuggestions(mapFeatures.suggestions);
  };

  const handleClick = (state_id: number, district_id?: number) => {
    setShowModal(false);
    setHasData(true);
    setSelectedValue("");
    setSearchTerm("");
    setSearchDisabled(false);
    setShowPlaceholder(true);
    const search = district_id
      ? `?country=1&state=${state_id}&district=${district_id}`
      : `?country=1&state=${state_id}`;
    navigate({
      pathname: RouteConstants.root,
      search: search,
    });
  };

  const handleRequestDataDrawer = () => {
    setRequestDataDrawerOpen(true);
    handleModalOpen(false);
  }

  return (
    <div className="p-0 m-0 d-flex justify-content-start">
      <Button
        theme={ButtonTheme.primary}
        size={ButtonSize.small}
        variant={ButtonVariant.bordered}
        onClick={() => handleModalOpen(true)}
        classname="d-flex align-items-center"
      >
        <IoSearchSharp className="margin-right-2 fs-20" />
        Search
      </Button>
      <Modal
        showModal={showModal}
        classname="width-62-5 h-100 margin-5 padding-1"
      >
        <div className="d-flex flex-row justify-content-between margin-bottom-2 margin-left-right-2">
          <Heading
            title="Search"
            type={TypographyType.h2}
            colour={TypographyColor.dark}
          />
          <Button
            theme={ButtonTheme.primary}
            variant={ButtonVariant.transparent}
            onClick={() => handleModalOpen(false)}
            type="button"
            classname="padding-left-right-0"
          >
            <AiOutlineClose className="fs-20" />
          </Button>
        </div>
        <div
          className=""
          style={{
            maxHeight: "68vh",
            minHeight: "68vh",
            minWidth: "56.27vw",
            maxWidth: "60vw",
          }}
        >
          <div className="margin-left-right-2">
            <Body
              type={BodyType.p2}
              color={BodyColor.muted}
              classname="text-start mb-1"
            >
              Following is the list of states and districts for which Points of Interest (PoI) data is available
            </Body>
            <div className="">
              <SearchWithSuggestions
                handleInputChange={handleInputChange}
                handleSelectValue={handleSelectValue}
                data={stateAndDistrict}
                searchTerm={searchTerm}
                suggestions={suggestions}
                labelKey="geo_value"
                valueKey="geo_value"
                hideSuggestionBox={false}
                placeholderValue={
                  showPlaceholder ? "Search by District or State" : ""
                }
                classname=""
                selectedValue={selectedValue}
                handleCloseSelected={handleCloseSelected}
                disabled={searchDisabled}
              />
            </div>
          </div>
          {hasData ? (
            <div
              className="margin-top-bottom-2 d-flex justify-content-center align-items-start"
              style={{
                height: "52vh",
                overflowY: "auto",
                overflowX: "hidden",
                width: "58vw",
              }}
            >
              {spinnerLite ? (
                <SpinnerLite />
              ) : (
                <div className="" style={{ width: "56.5vw" }}>
                  {results?.map(
                    (item: any) =>
                      item.has_data && (
                        <div key={item.geo_id} className="margin-top-bottom-2">
                          <div className="d-flex flex-row align-items-center justify-content-start mb-1">
                            <Heading
                              title={item.geo_value}
                              type={TypographyType.h4}
                              colour={TypographyColor.purple}
                              classname={`text-start mb-0 me-1 width-fit-content ${item.geo_value === selectedValue ? 'bg-purple-4' : ''}`}
                              onClick={() => handleClick(item.geo_id)}
                            />
                            <FaArrowRightLong fontSize={20} color="#2b0d5c" />
                          </div>

                          <hr className="margin-top-0"></hr>
                          <div className="row margin-0 padding-0">
                            {item?.children?.map(
                              (district: any) =>
                                item.has_data && (
                                  <Body
                                    type={BodyType.p1}
                                    color={BodyColor.purple}
                                    classname='col-4 text-start margin-bottom-1 padding-0 '
                                    key={district.geo_id}
                                    onClick={() => handleClick(item.geo_id, district.geo_id)}
                                  >
                                    <span className={`${district.geo_value === selectedValue ? "bg-purple-4" : ""}`}>
                                      {district.geo_value}
                                    </span>
                                  </Body>
                                )
                            )}
                          </div>
                        </div>
                      )
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center">
              <div
                className="mx-4 margin-top-5 margin-bottom-0 d-flex flex-column justify-content-center align-items-center"
                style={{ width: "25rem" }}
              >
                <img
                  src={WorkInProgressImage}
                  className="wip-img"
                  alt="Work in progress"
                  width="45%"
                />
                <Heading
                  title="The location you’re searching for is currently unavailable."
                  type={TypographyType.h5}
                  colour={TypographyColor.dark}
                  classname="padding-top-3 margin-bottom-1"
                />
                <Body
                  type={BodyType.p3}
                  color={BodyColor.dark}
                  classname="text-center margin-top-bottom-3 margin-left-right-0"
                >
                  Our team is actively developing these features for the
                  upcoming updates. Keep an eye out for more information.
                </Body>
                <div className="d-flex flex-row justify-content-center align-items-center w-100">
                  <Button
                    theme={ButtonTheme.secondary}
                    size={ButtonSize.medium}
                    variant={ButtonVariant.bordered}
                    classname="height-3"
                    style={{ width: 'fit-content', padding: '0px 24px', marginRight: '30px' }}
                    onClick={() => handleRequestDataDrawer()}
                  >
                    Request Data
                  </Button>
                  <Button
                    theme={ButtonTheme.primary}
                    size={ButtonSize.medium}
                    variant={ButtonVariant.bordered}
                    classname="height-3"
                    onClick={() => handleViewAvailableStates()}
                  >
                    View available regions
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ExploreNow;

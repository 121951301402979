import axios from 'axios';

// Utilities
import { APIS } from '../constants';
import {useDb} from '../indexDB/db';


const useIDBService = () => {
    const db = useDb();

    const postUserMetrics = async () => {
    const userMetrics = await db.getAllItems();
 
        if (userMetrics && userMetrics.length > 0) {
            try {
                const token = JSON.parse(localStorage.getItem('user') || '{}')?.tokens?.access;
                const headers = {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                };

                const response = await axios.post(setURL( APIS.ANALYTICS.USERMETRICS), userMetrics, { headers });
                console.log('userMetrics posted successfully', response.data);
                await db.clearAllItems();
                console.log('IndexedDB cleared successfully');
            } catch (error) {
                console.error('Error while posting userMetrics:', error);
            }
        } else {
            console.log('There is no data in userMetrics to post');
        }
    };

    function setURL(url: string) {
        let host = process.env.REACT_APP_API_HOST_DEV;
        let baseURL = process.env.REACT_APP_API_BASE_URL;
        if (window.location.host === host) {
            url = baseURL + url;
        }
        return url;
    }

    return {
        postUserMetrics
    }
}

export { useIDBService };

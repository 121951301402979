import React, { useState } from 'react';
import { GoSearch } from 'react-icons/go';
import styles from './Search.module.css';
import {
    Button,
    ButtonTheme,
    ButtonSize,
    ButtonVariant,
} from "../button/Button";
import {
    Heading,
    TypographyColor,
    TypographyType,
} from "../typography/Heading";
import { AiOutlineClose } from 'react-icons/ai';

interface SearchWithSuggestionsProps {
    handleInputChange: (value: string) => void;
    handleSelectValue: (value: string) => void;
    data?: any;
    searchTerm: string;
    suggestions?: any;
    labelKey?: any;
    valueKey?: any;
    hideSuggestionBox: boolean;
    placeholderValue: string;
    classname?: string;
    selectedValue: string;
    handleCloseSelected: () => void;
    disabled?: boolean;
}

const SearchWithSuggestions = ({ handleInputChange, handleSelectValue, searchTerm, suggestions, labelKey, valueKey, hideSuggestionBox, placeholderValue, classname, selectedValue, handleCloseSelected, disabled }: SearchWithSuggestionsProps) => {
    const [hideSuggestions, setHideSuggestions] = useState<boolean>(true);

    const handleSelectOption = (value: any) => {
        handleSelectValue(value);
    }

    return (
        <div className={`d-flex flex-column h-auto mt-1 ${styles.suggestion_menu}`}>
            <div className={`d-flex flex-column justify-content-center align-items-start ${styles.search}`}>
                <input
                    onFocus={() => setHideSuggestions(false)}
                    onBlur={async () => {
                        setTimeout(() => {
                            setHideSuggestions(true);
                        }, 200);
                    }}
                    type="text"
                    placeholder={placeholderValue}
                    value={searchTerm}
                    className={`${styles.searchInput} ${classname}`}
                    disabled={disabled}
                    onChange={(e) => {
                        handleInputChange(e.target.value);
                    }}
                />
                <span className={styles.icon}>
                    <GoSearch className="fs-22" />
                    {selectedValue && (
                        <div className='d-flex flex-row align-items-center ms-3'>
                            <Heading
                                title={selectedValue}
                                type={TypographyType.h4}
                                colour={TypographyColor.dark}
                                classname="margin-bottom-0"
                            />
                            <Button
                                theme={ButtonTheme.primary}
                                variant={ButtonVariant.transparent}
                                onClick={() => handleCloseSelected()}
                                type="button"
                                classname="padding-left-right-0"
                            >
                                <AiOutlineClose className="fs-20" />
                            </Button>
                        </div>
                    )}
                </span>

            </div>

            {!hideSuggestionBox && suggestions.length > 0 &&
                <div
                    className={`padding-top-bottom-1 ${classname} ${hideSuggestions ? styles.suggestions_hidden : styles.suggestions_visible} ${styles.suggestion_menu_dropdown} ${suggestions?.length < 3 ? 'h-auto' : styles.suggestion_height}`}
                >
                    {suggestions?.map((suggestion: any) => (
                        <div
                            className={`text-start padding-top-bottom-1 ${styles.suggestions_item}`}
                            onClick={() => handleSelectOption(suggestion[labelKey])}
                        >
                            <p className='padding-left-right-3 margin-0' >{suggestion[labelKey]}</p>
                        </div>
                    ))}
                </div>
            }
        </div>
    )
}

export default SearchWithSuggestions;
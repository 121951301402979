const RouteConstants: any = {
    root: '/',
    login: '/login',
    explore: '/explore',
    update_password: '/update-password',
    dashboards: '/dashboard',
    stories: '/stories',
    story_details: '/story-details',
    profile: '/profile',
    settings: '/settings',
    invite: '/invite',
    families: '/families',
    something_went_wrong: '/something-went-wrong',
    error: '/error',
    not_found: '/not-found',
}

export { RouteConstants };
import React, { Component, ReactNode } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { RouteConstants } from '../constants';

interface ErrorBoundaryProps {
    navigate: NavigateFunction;
    children: ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error: Error, info: React.ErrorInfo) {
        console.error('ErrorBoundary caught an error', error, info);
        this.setState({ hasError: true });

        // Using setTimeout to ensure the state update happens before navigation
        setTimeout(() => {
            // this.props.navigate(RouteConstants.something_went_wrong);
            window.location.reload();
        }, 0);
    }

    render() {
        if (this.state.hasError) {
            // Return null to avoid rendering any component
            return null;
        }

        return this.props.children;
    }
}

// Higher-order component to pass navigate to ErrorBoundary
function ErrorBoundaryWithNavigate(props: { children: ReactNode }) {
    const navigate = useNavigate();
    return <ErrorBoundary navigate={navigate}>{props.children}</ErrorBoundary>;
}

export default ErrorBoundaryWithNavigate;
